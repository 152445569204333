header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    max-width: 80rem;
    margin: auto;
    padding: 1rem 0 0 0;
    margin-bottom: 1rem;

    > img {
        width: 8rem;
    }

    a {
        text-decoration: none;
        padding-bottom: 1.75rem;
        border-bottom: 4px solid transparent;

        &:not(:last-child) {
            margin-right: 2rem;
        }

        &:hover {
            border-image-slice: 2;
            border-image-source: linear-gradient(90deg, var(--green), var(--cyan));
        }
    }

    .menu-btn {
        display: none;
        height: 2.5rem;
        width: 2.5rem;
        background: none;
        outline: none;
        border: none;
        align-items: center;
        justify-content: center;
    }

    .backdrop {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: black;
        opacity: 0.5;
    }
}