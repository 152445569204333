@media (max-width: 1500.98px) {
    .introduction {
        background-position: right -60% top 70% !important;
    }
}

@media (max-width: 1400.98px) {
    .introduction .cellphones {
        width: 38rem !important;
    }
    .latest-section .grid-item .grid-content {
        padding: 1rem !important;
    }
}

@media (max-width: 1300.98px) {
    .introduction .container > div {
        width: 50% !important;
    }
    .introduction .cellphones {
        width: 30rem !important;
    }
    .introduction {
        padding: 4rem 0 !important;
        background-position: right -80% top 70% !important;
    }
    .latest-section .grid-item {
        height: max-content;
    }
}
@media (max-width: 1200.98px) {
    footer > .container #left-side > nav {
        margin-left: 5rem !important;
    }
}

@media (max-width: 1100.98px) {
    .introduction .container > div {
        width: 60% !important;
    }

    .introduction .cellphones {
        width: 45% !important;
    }
    .latest-section .grid-item {
        height: auto;
    }
    .latest-section .grid {
        grid-template: repeat(2, 1fr) / repeat(2, 1fr) !important;
    }
}

@media (max-width: 1000.98px) {
    .introduction {
        background-position: right -90% top 70% !important;
    }

    .introduction .cellphones {
        width: 50% !important;
        right: -10% !important;
    }
    section .grid {
        grid-template: repeat(2, 1fr) / repeat(2, 1fr) !important;
    }
    footer > .container #left-side > nav {
        margin-left: 4rem !important;
    }
    footer > .container #left-side > nav {
        column-gap: 2rem !important;
    }
}

@media (max-width: 900.98px) {
    .introduction .container > div {
        width: 100% !important;
        text-align: center;
    }
    .introduction .container > div p {
        width: 100% !important;
    }
    .introduction {
        padding-top: 20rem !important;
        background-position: center top -8rem !important;
        overflow: hidden !important;
    }
    .introduction .cellphones {
        right: 25% !important;
        top: -8rem !important;
    }
    footer > .container {
        flex-direction: column !important;
        align-items: center !important;

        #left-side {
            flex-direction: column !important;
            align-items: center !important;

            > div {
                > img {
                    margin-bottom: 2rem !important;
                }

                > .icons {
                    margin-bottom: .625rem !important;
                }
            }

            > nav {
                column-count: auto !important;
                margin-left: 0 !important;
                text-align: center;

                a {
                    margin: 1.375rem 0 !important;
                }
            }
        }

        #right-side {
            align-items: center !important;
            margin-top: 1rem;
        }
    }
    header .btn {
        display: none;
    }
    header {
        padding: 2rem 0 0 0;
    }
    header a {
        padding-bottom: 0.75rem;
    }
}

@media (max-width: 768.98px) {
    .introduction {
        padding-top: 18rem !important;
    }
    section {
        padding: 3rem 0;
    }
    section {
        br {
            display: none;
        }
        h2, p {
            text-align: center;
        }
    }
    .grid-item {
        text-align: center;
    }
    .menu-btn {
        display: flex !important;
    }
    .mobile-retracted {
        display: none;
    }
    header .mobile-expanded {
        position: absolute;
        z-index: 1;
        top: 6rem;
        right: 5%;
        display: flex;
        flex-direction: column; 
        background-color: var(--white);
        width: 90%;
        border-radius: .5rem;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;

        a {
            margin-right: 0 !important;
            color: var(--blue) !important;
            text-align: center;
            border-image-slice: inherit !important;
            border-image-source: none !important;
            padding: 1rem 0 !important;
        }
    }
}

@media (max-width: 700.98px) {
    .introduction .container > div h1 {
        font-size: 3rem !important;
    }
    .introduction {
        background-position: center top -5rem !important;
    }
    .introduction .cellphones {
        top: -5rem !important;
    }

    section .grid, .latest-section .grid {
        grid-template: 1fr / 1fr !important;
    }
    .latest-section .grid-item {
        height: fit-content;
    }
}

@media (max-width: 600.98px) {
    .introduction {
        background-position: center top -2rem !important;
    }
    .introduction .cellphones {
        top: -2rem !important;
    }
}
@media (max-width: 450.98px) {
    .introduction {
        background-size: 100% !important;
        background-position: center -2rem !important;
    }
    .introduction .cellphones {
        top: -2rem !important;
        width: 75% !important;
        right: 12.5% !important;
    }
    .introduction .container > div h1 {
        font-size: 2.5rem !important;
    }
}
@media (max-width: 350.98px) {
    .introduction .container > div h1 {
        font-size: 2.25rem !important;
    }
    .introduction {
        padding-top: 16rem !important;
    }
}