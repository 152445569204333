.introduction {
    width: 100%;
    position: relative;
    padding: 9rem 0;
    background: url("../assets/bg-intro-desktop.svg") no-repeat;
    background-size: 75%;
    background-position: right -85% top 70%;

    .container {
        display: flex;
        flex-direction: row;
        margin: auto;
        width: 90%;
        max-width: 80rem;
        overflow: hidden;
        

        > div {
            width: 40%;
            
            h1 {
                font-size: 3.5rem;
                margin: 0 0 2rem 0;
            }

            p {
                width: 75%;
                margin-bottom: 2rem;
            }
        }
    }
    
    .cellphones {
        position: absolute;
        width: 40rem;
        top: 0;
        right: 0;
    }
}