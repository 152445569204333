.latest-section {
    h2 {
        margin-bottom: 3rem;
    }

    .grid {
        grid-gap: 2rem;
    }

    .grid-item {
        background-color: var(--white);
        border-radius: .5rem;
        box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;

        img{
            width: 100%;
            object-fit: cover;
            height: 40%;
            border-radius: .5rem .5rem 0 0;
        }

        .grid-content{
            display: flex;
            flex-direction: column;
            padding: 1rem 2rem 0 2rem;

            .author {
                margin: 0;
                font-size: .875rem;
            }

            h3 {
                margin: .5rem 0;
                font-size: 1.25rem;
            }
            
            a {
                color: var(--blue);
                cursor: pointer;
                text-decoration: none;

                &:hover {
                    color: var(--green);
                }
            }
        }
    }

}