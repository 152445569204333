footer {
    background-color: var(--blue);
    padding: 3rem 0;

    > .container {
        width: 90%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        #left-side {
            display: flex;
            align-items: stretch;
            justify-content: stretch;

            > div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                > img {
                    width: 10rem;
                }

                .icons {
                    a:not(:last-child) {
                        margin-right: 1.25rem;
                    }

                    svg {
                        transform: scale(1.25);

                        &:hover {
                            path {
                                fill: var(--green);
                            }
                        }
                    }
                }
            }

            > nav {
                column-count: 2;
                column-gap: 5rem;
                margin-left: 10rem;

                a {
                    display: block;
                    text-decoration: none;

                    &:hover {
                        color: var(--green);
                    }

                    &:nth-child(2), &:nth-child(5) {
                        margin: 1.375rem 0;
                    }
                }
            }
        }

        #right-side {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            p {
                margin: 2rem 0 0 0;
            }
        }
    }
}